import { Drawer, message } from "antd";
import React, { useRef, useEffect, useState } from "react";
import {
  getProductsWithIndexes,
  getSortedProductsByTimeAdded,
  searchProductInDb,
  searchProductInDbByTitle,
} from "../../../../db/products-functions";
import FindedProductsTable from "../../../products/FindedProductsTable";
import sound from "../../../UI/sound/beep-09.mp3";
import { playSound } from "../../../../utils/play-sound";
import TableLastEnteredProducts from "./TableLastEnteredProducts";
import VirtualKeyboard from "../../VirtualKeyboard";
import {
  DOCUMENTS_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../_CONSTANTS/constants";
import {
  addProductToIndexDb,
  getExistingProductFromDocument,
  getProductPrice,
  getProductsToTable,
  getProductsWithNew,
  updateProductsInTable,
} from "./enter-products-functions";
import DefaultModalBody from "./DefaultModalBody";
import DefaultModalFooter from "./DefaultModalFooter";
import { getUserSetting } from "../../../../api/settings/settings";
import ModalEnterCharacteristics from "../modal-enter-characteristics/ModalEnterCharacteristics";
import { getProductCharacteristicsFromCurrentDocument } from "../modal-enter-characteristics/characteristics-functions";
import { useAuth } from "../../../../hook/useAuth";
import ModalProductInfo from "../modal-product-info/ModalProductInfo";
import { sortArrayOfObjectsByTitle } from "../../../../utils/array-functions";
import ModalEnterExpirationDate from "../modal-enter-expiration-date/ModalEnterExpirationDate";
import { getAllRecords } from "../../../../db/dexie-db/functions";
import { updateNewQtyDisplayData } from "../../UI/ui-functions";
import dayjs from "dayjs";
import { CloseOutlined } from "@ant-design/icons";

function ModalEnterProduct(props) {
  const { user } = useAuth();
  const inputDataRef = useRef();
  const inputQtyRef = useRef();
  const inputDataPrice = useRef();

  useEffect(() => {
    if (
      props?.backgroundEnteredBarcode &&
      props?.backgroundEnteredBarcode !== ""
    ) {
      processBackGroundEnteredBarcode(props.backgroundEnteredBarcode);
    }
  }, [props?.backgroundEnteredBarcode]);

  const [last3Products, setLast3Products] = useState();
  const [currentProduct, setCurrentProduct] = useState();
  const [findedProducts, setFindedProducts] = useState([]);
  const [isVisibleFindedProducts, setIsVisibleFindedProducts] = useState(false);
  const [focusedInput, setFocusedInput] = useState();
  const [isModalProductInfoOpen, setIsModalProductInfoOpen] = useState(false);
  const [productForShowInfo, setProductForShowInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalEnterCharacteristicsOpen, setIsModalEnterCharacteristicsOpen] =
    useState(false);
  const [isVirtualKeyboardVisible, setIsVirtualKeyboardVisible] =
    useState(false);

  const [isModalEnterExpirationDateOpen, setIsModalEnterExpirationDateOpen] =
    useState(false);

  const useCharacteristics = getUserSetting(
    "useCharacteristics",
    user.settings,
    "global"
  );

  const useEnteringExpirationDates = getUserSetting(
    "useEnteringExpirationDates",
    user.settings,
    "global"
  );

  const FooterAddon = props?.FooterAddon ? props?.FooterAddon : "";

  const processBackGroundEnteredBarcode = async (enteredData) => {
    const product = await searchProductInDb(enteredData, props.weightTemplate);
    if (!product) {
      return processProductNotFound(enteredData);
    }
    props.setIsModalProductOpen(true);
    processSearchProduct(enteredData, props.products);
    props.setBackgroundEnteredBarcodeValue("");
  };

  const processProductNotFound = (enteredData) => {
    if (props?.settings?.sounds) {
      playSound(sound);
    }
    message.error("Товар " + enteredData + " не знайдено!");
    setCurrentProduct(undefined);
    setModalTitle(undefined);
    setProductPrice("");
    inputDataRef?.current?.focus();
  };

  const onRowClick = async (record) => {
    if (useEnteringExpirationDates && useCharacteristics) {
      // знайти характеристики товару у документі
      const existingProductCharacteristics =
        await getProductCharacteristicsFromCurrentDocument(
          props.dbTable,
          record
        );
      if (existingProductCharacteristics)
        record.characteristics = existingProductCharacteristics;

      return showModalEnterExpirationDate(record);
    }

    if (useCharacteristics && Boolean(record?.characteristics))
      return showModalCharacteristics(record);

    setModalTitle(record);
    setCurrentProduct(record);

    setProductPrice(record?.price);
    setIsVisibleFindedProducts(false);
    setFindedProducts([]);
    inputQtyRef.current.focus();
  };

  const onContextMenu = (record) => {
    setProductForShowInfo(record);
    setIsModalProductInfoOpen(true);
  };

  const processSearchProductsByTitle = async (enteredData) => {
    setIsLoading(true);
    const products = await searchProductInDbByTitle(enteredData);

    if (!products.length || !products[0]) {
      setIsLoading(false);
      return processProductNotFound(enteredData);
    }
    if (products.length === 1) {
      setIsLoading(false);
      return processFindedProduct(products[0], props.products);
    }
    setIsVisibleFindedProducts(true);

    const sortedByTitleProducts = products.sort(sortArrayOfObjectsByTitle);
    setFindedProducts(sortedByTitleProducts);
    setIsLoading(false);
    return;
  };

  const processSearchProduct = async (
    enteredData,
    products = props.products
  ) => {
    if (!enteredData) return message.info("Введіть дані");
    if (!Number(enteredData)) {
      return processSearchProductsByTitle(enteredData);
    }
    if (isVisibleFindedProducts)
      setIsVisibleFindedProducts(!isVisibleFindedProducts);

    const product = await searchProductInDb(enteredData, props.weightTemplate);
    if (!product) {
      return processProductNotFound(enteredData);
    }

    if (useEnteringExpirationDates && useCharacteristics) {
      // знайти характеристики товару у документі
      const existingProductCharacteristics =
        await getProductCharacteristicsFromCurrentDocument(
          props.dbTable,
          product
        );
      if (existingProductCharacteristics)
        product.characteristics = existingProductCharacteristics;

      return showModalEnterExpirationDate(product);
    }

    if (useCharacteristics && product?.characteristics) {
      return showModalCharacteristics(product);
    }

    return processFindedProduct(product, products);
  };

  const showModalCharacteristics = (product) => {
    setCurrentProduct(product);
    setIsModalEnterCharacteristicsOpen(true);
  };

  const showModalEnterExpirationDate = (product) => {
    setCurrentProduct(product);
    setIsModalEnterExpirationDateOpen(true);
  };

  const afterCloseModalEnterCharacteristics = async () => {
    // await afterCloseModalEnterProduct();
    if (findedProducts.length) setIsVisibleFindedProducts(false);
    afterChangeProductQty();
    inputDataRef.current.focus();
  };

  const afterCloseModalEnterProduct = async () => {
    if (props?.setBackgroundEnteredBarcodeValue)
      props.setBackgroundEnteredBarcodeValue("");
    setCurrentProduct(undefined);
    setModalTitle(undefined);
    setProductPrice("");
    inputQtyRef.current.value = "";
    inputDataRef.current.value = "";

    if (props.dbTable) {
      await updateProductsInTable(props.dbTable, props.setProducts);
    }
  };

  const onClickAddToTableCheckPrice = async () => {
    if (!currentProduct) {
      return message.error("Виберіть товар!");
    }
    const product = { ...currentProduct };
    product.qty = +inputQtyRef.current.value ? +inputQtyRef.current.value : 1;

    const existingProduct = await getExistingProductFromDocument({
      product,
      products: props.products,
      dbTable: props.dbTable,
    });

    if (existingProduct) {
      product.qty = existingProduct.qty + product.qty;
    }

    addProductToCurrentDocument(product, props.products);
    inputQtyRef.current.value = "";
    setCurrentProduct(undefined);
    setModalTitle(undefined);
    inputDataRef.current.focus();
  };

  const enterHandlerCheckPrice = async (
    enteredData,
    products = props.products
  ) => {
    if (!enteredData) return message.info("Введіть дані");

    if (enteredData.length < 6 && currentProduct) {
      return await processAddProductToDocument(
        currentProduct,
        enteredData,
        products
      );
    }
    processSearchProduct(enteredData, products);
  };

  const enterHandler = async (enteredData, products = props.products) => {
    if (!enteredData && !currentProduct) {
      return message.info("Введіть дані");
    }
    if (!currentProduct) return processSearchProduct(enteredData, products);

    if (findedProducts.length) setIsVisibleFindedProducts(false);
    const product = { ...currentProduct };

    return processAddProductToDocument(product, enteredData, products);
  };

  const processAddProductToDocument = async (
    product,
    enteredData,
    products
  ) => {
    if (enteredData.length > 6 || !Number(enteredData)) {
      product.qty = product?.isWeight ? 0.01 : 1;
    } else {
      const enteredQty = product?.isWeight
        ? +enteredData
        : parseInt(+enteredData);
      product.qty = enteredQty;
      enteredData = "";
    }

    const existingProduct = await getExistingProductFromDocument({
      product,
      products,
      dbTable: props.dbTable,
    });

    if (existingProduct) {
      product.qty = existingProduct.qty + product.qty;
    }

    if (props?.settings?.askPrice && inputDataPrice.current) {
      const newPrice = getProductPrice(
        inputDataPrice,
        existingProduct,
        product,
        products
      );
      product.price = newPrice;
    }

    addProductToCurrentDocument(product, products);
    if (enteredData === "") {
      inputDataRef.current.focus();
      setCurrentProduct(undefined);
      setModalTitle(undefined);
      setProductPrice("");
    } else {
      processSearchProduct(enteredData, products);
    }
  };

  const addProductWithCharacteristicsToCurrentDocument = (
    productStructure,
    products = props.products
  ) => {
    addProductToCurrentDocument(productStructure, products);
  };

  const addProductToCurrentDocument = async (product, products) => {
    product.timeAdded = +dayjs().valueOf().toString();
    product.key = product.timeAdded;

    if (props.dbTable) {
      const added = await addProductToIndexDb(props.dbTable, product);
      if (!added) return message.error("Помилка додавання товару у документ!");
      setLast3Products(await get3LastProducts());
    } else {
      const newProducts = getProductsWithNew(product, products);
      const productsToTable = getProductsToTable(newProducts);
      props.setProducts(productsToTable);
    }
  };

  const get3LastProducts = async () => {
    const productsWithNew = await getAllRecords(props.dbTable);
    const sortedProducts = getSortedProductsByTimeAdded(productsWithNew);
    const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);
    return sortedProductsWithIndexes.slice(-3);
  };

  const processFindedProduct = async (product, products) => {
    const existingProduct = await getExistingProductFromDocument({
      product,
      products,
      dbTable: props.dbTable,
    });

    if (product.isWeight && product.qty) {
      const qtyFromBarcode = +product.qty;
      if (existingProduct) {
        product.qty = +existingProduct.qty + qtyFromBarcode;
      }

      addProductToCurrentDocument(product, products);
      setModalTitle(undefined);
      setCurrentProduct(undefined);
      setProductPrice("");

      return message.success(`Додано: ${qtyFromBarcode} ${product.title} `);
    }

    if (existingProduct) {
      product.qty = existingProduct.qty;
    }
    updateNewQtyDisplayData(false, product);

    setModalTitle(product);
    setCurrentProduct(product);
    setProductPrice(product?.price);

    if (props?.focusInputQty) inputQtyRef.current.focus();
  };

  const setProductPrice = (price) => {
    if (inputDataPrice.current) inputDataPrice.current.value = price;
  };
  const onFocusDataInput = () => {
    if (props?.settings?.showVirtualKeyboard) {
      setFocusedInput(inputDataRef);
      setIsVirtualKeyboardVisible(true);
    }
  };

  const onFocusPriceInput = () => {
    if (props?.settings?.showVirtualKeyboard && inputDataPrice.current)
      setFocusedInput(inputDataPrice);
    inputDataPrice.current.value = "";
  };

  const onFocusQtyInput = () => {
    if (props?.settings?.showVirtualKeyboard) {
      setFocusedInput(inputQtyRef);
      setIsVirtualKeyboardVisible(true);
    }
  };

  const onBlurDataInput = () => {
    if (props?.settings?.showVirtualKeyboard && isVirtualKeyboardVisible)
      setIsVirtualKeyboardVisible(false);
  };
  const onBlurQtyInput = () => {
    if (props?.settings?.showVirtualKeyboard && isVirtualKeyboardVisible)
      setIsVirtualKeyboardVisible(false);
  };

  const decrementQty = () => {
    if (!currentProduct) return message.error("Не вибраний товар!");
    const qtyInputValue = inputQtyRef.current.value;
    if (
      qtyInputValue === 0 ||
      qtyInputValue === "" ||
      qtyInputValue === 1 ||
      qtyInputValue <= 0
    ) {
      inputQtyRef.current.value = currentProduct?.isWeight ? 0.01 : 1;
    } else {
      inputQtyRef.current.value = qtyInputValue - 1;
    }
    updateNewQtyDisplayData(+inputQtyRef.current.value, currentProduct);
  };

  const incrementQty = () => {
    if (!currentProduct) return message.error("Не вибраний товар!");
    const qtyInputValue = +inputQtyRef.current.value;
    if (qtyInputValue === 0 || qtyInputValue === "") {
      inputQtyRef.current.value = 1;
    } else {
      inputQtyRef.current.value = qtyInputValue + 1;
    }
    updateNewQtyDisplayData(+inputQtyRef.current.value, currentProduct);
  };

  const onClickAddToTable = () => {
    const enteredData = inputQtyRef.current.value;
    inputQtyRef.current.value = "";
    inputDataRef.current.value = "";
    const formattedEnteredData = enteredData.replace(/,/g, ".");
    if (inputDataPrice.current)
      inputDataPrice.current.value = inputDataPrice.current.value.replace(
        /,/g,
        "."
      );
    enterHandler(formattedEnteredData);
    inputDataRef.current.focus();
  };

  const afterChangeProductQty = async () => {
    if (props.dbTable) setLast3Products(await get3LastProducts());
    inputDataRef.current.focus();
  };

  const footer = (
    <div key={"modal-enter-product-footer"}>
      <DefaultModalFooter
        key="DefaultModalFooter"
        onClickAddToTable={
          props?.serviceTitle === DOCUMENTS_TITLES.CHECK_PRICE
            ? onClickAddToTableCheckPrice
            : onClickAddToTable
        }
        askPrice={props?.settings?.askPrice}
        inputDataPrice={inputDataPrice}
        onFocusPriceInput={onFocusPriceInput}
        onBlurPriceInput={onBlurDataInput}
        FooterAddon={FooterAddon ? <FooterAddon></FooterAddon> : ""}
      ></DefaultModalFooter>

      {isVisibleFindedProducts && (
        <>
          <FindedProductsTable
            key="FindedProductsTable"
            findedProducts={findedProducts}
            onRowClick={onRowClick}
            onContextMenu={onContextMenu}
            isloading={isLoading}
          ></FindedProductsTable>
          <ModalProductInfo
            open={isModalProductInfoOpen}
            setIsOpen={setIsModalProductInfoOpen}
            setIsModalProductInfoOpen={setIsModalProductInfoOpen}
            product={productForShowInfo}
          ></ModalProductInfo>
        </>
      )}
      {props?.action === DOCUMENT_OPEN_ACTIONS.CREATE && props.columns && (
        <TableLastEnteredProducts
          lastEnteredProducts={last3Products}
          afterChangeProductQty={afterChangeProductQty}
          isVisible={!isVisibleFindedProducts}
          removeProductFromDocument={props?.removeProductFromDocument}
          isloading={isLoading}
          afterCloseModalEnterCharacteristics={
            afterCloseModalEnterCharacteristics
          }
          addProductWithCharacteristicsToCurrentDocument={
            addProductWithCharacteristicsToCurrentDocument
          }
          settings={props.settings}
          dbTable={props.dbTable}
          columns={props.columns}
        ></TableLastEnteredProducts>
      )}
      {props?.settings?.showVirtualKeyboard && (
        <VirtualKeyboard
          key="VirtualKeyboard"
          isVirtualKeyboardVisible={isVirtualKeyboardVisible}
          focusedInput={focusedInput ? focusedInput : inputDataRef}
          updateNewQtyDisplayData={updateNewQtyDisplayData}
          currentProduct={currentProduct}
          onConfirm={
            props?.serviceTitle === DOCUMENTS_TITLES.CHECK_PRICE ||
            props?.autoAddProductAfterNextScan === false
              ? enterHandlerCheckPrice
              : enterHandler
          }
        ></VirtualKeyboard>
      )}
    </div>
  );

  const setModalTitle = (product) => {
    if (product === currentProduct) return;
    setTimeout(() => {
      const modalTitle = document.getElementById("modalTitle");
      if (!modalTitle) return;
      const currentProductStockData = document.getElementById(
        "currentProductStockData"
      );
      const currentProductPriceData = document.getElementById(
        "currentProductPriceData"
      );
      const enteredQtyData = document.getElementById("enteredQtyData");
      if (!product) {
        modalTitle.textContent = "Введіть дані";
        if (currentProductStockData) currentProductStockData.textContent = "";
        if (currentProductPriceData) currentProductPriceData.textContent = "";
        if (enteredQtyData) enteredQtyData.textContent = "";
        return;
      }
      const productTitle = product.title;
      const price = "Ціна: " + product.price.toFixed(2);
      const showStockQty = props?.showStockQty;
      const stockQty = showStockQty === false ? 0 : product.stockQty;
      const stock = "Залишок: " + stockQty;
      modalTitle.textContent = productTitle;
      if (currentProductStockData) {
        currentProductStockData.textContent = stock;
        currentProductStockData.append(document.createElement("br"));
      }
      if (currentProductPriceData) currentProductPriceData.textContent = price;
    }, 100);
  };

  const newModalTitle = <span id="modalTitle">Введіть дані</span>;

  return (
    <>
      {isModalEnterCharacteristicsOpen && (
        <ModalEnterCharacteristics
          key="ModalEnterCharacteristics"
          isModalEnterCharacteristicsOpen={isModalEnterCharacteristicsOpen}
          setIsModalEnterCharacteristicsOpen={
            setIsModalEnterCharacteristicsOpen
          }
          product={currentProduct}
          setIsModalProductOpen={props.setIsModalProductOpen}
          products={props.products}
          afterChangeProductQty={afterCloseModalEnterCharacteristics}
          addProductToCurrentDocument={
            addProductWithCharacteristicsToCurrentDocument
          }
          dbTable={props.dbTable}
          serviceTitle={props?.serviceTitle}
        ></ModalEnterCharacteristics>
      )}

      {isModalEnterExpirationDateOpen && (
        <ModalEnterExpirationDate
          key="ModalEnterExpirationDate"
          setIsModalEnterExpirationDateOpen={setIsModalEnterExpirationDateOpen}
          isModalEnterExpirationDateOpen={isModalEnterExpirationDateOpen}
          product={currentProduct}
          setIsModalProductOpen={props.setIsModalProductOpen}
          products={props.products}
          afterClose={afterCloseModalEnterCharacteristics}
          addProductToCurrentDocument={
            addProductWithCharacteristicsToCurrentDocument
          }
          dbTable={props.dbTable}
          serviceTitle={props?.serviceTitle}
        ></ModalEnterExpirationDate>
      )}
      <Drawer
        push={{ distance: 0 }}
        afterOpenChange={(open) => {
          if (open && !currentProduct) {
            requestAnimationFrame(() => {
              inputDataRef.current.focus();
            });
          }
        }}
        title={newModalTitle}
        open={props.isModalProductOpen}
        onClose={async () => {
          props.setIsModalProductOpen(false);
          await afterCloseModalEnterProduct();
        }}
        styles={{
          body: { padding: "3px 10px", overflow: "hidden", minHeight: "10vh" },
          header: { padding: "10px" },
          footer: { padding: "10px" },
        }}
        style={{ height: "auto", maxHeight: "100vh" }}
        height={window.screen.availHeight}
        placement="top"
        closable={false}
        extra={
          <CloseOutlined
            onClick={async () => {
              props.setIsModalProductOpen(false);
              await afterCloseModalEnterProduct();
            }}
          ></CloseOutlined>
        }
        footer={footer}
      >
        {currentProduct?.info && !currentProduct?.characteristics && (
          <i style={{ textDecoration: "underline" }}>{currentProduct.info}</i>
        )}
        <DefaultModalBody
          key="DefaultModalBody"
          inputDataRef={inputDataRef}
          settings={props.settings}
          enterHandler={
            props?.serviceTitle === DOCUMENTS_TITLES.CHECK_PRICE ||
            props?.autoAddProductAfterNextScan === false
              ? enterHandlerCheckPrice
              : enterHandler
          }
          onFocusDataInput={onFocusDataInput}
          decrementQty={decrementQty}
          inputQtyRef={inputQtyRef}
          onFocusQtyInput={onFocusQtyInput}
          updateNewQtyDisplayData={updateNewQtyDisplayData}
          currentProduct={currentProduct}
          incrementQty={incrementQty}
          BodyAddon={props?.BodyAddon}
          processSearchProduct={processSearchProduct}
          onBlurDataInput={onBlurDataInput}
          onBlurQtyInput={onBlurQtyInput}
        ></DefaultModalBody>
      </Drawer>
    </>
  );
}

export default ModalEnterProduct;
